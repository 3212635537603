import * as d3 from "d3";
import { cloneDeep } from "lodash";
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Handle, useReactFlow, useStore } from "react-flow-renderer";
import ForceGraph3D from "react-force-graph-3d";
import * as THREE from "three";
import SpriteText from "three-spritetext";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import createNodesAndLinks from "./helper";
import newtab from "../ReactFlowComponents/Assets/svgs/newtab.svg";
import sphere from "../../../assets/png/sphere.png";
import selectedEllipse from "../../../assets/png/selectedEllipse.png";
import { TaiContext } from "../TaiContextProvider";
import { cohortTypes } from "../components/constants";
import { getJSONDataFromURL } from "utils/jsonFromURL";
import { MenuItem, Select, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom-v5-compat";

const changeSelectedNodeColors = (nodesData, clickedNode, checkedCohorts) => {
  if (clickedNode) {
    const allConnectedNodesIds = [clickedNode["id"]];
    nodesData.links.forEach((l) => {
      let source = l.source["id"] || l.source;
      let target = l.target["id"] || l.target;

      if (source === clickedNode["id"]) {
        allConnectedNodesIds.push(target);
      } else if (target === clickedNode["id"]) {
        allConnectedNodesIds.push(source);
      }
    });

    //if connected nodes are child
    //remove all child except the selected nodes
    const allChildNodes = [];
    const nodes = nodesData.nodes
      .map((n) => {
        if (n.type === "child") {
          allChildNodes.push(n.id);
        }
        if (checkedCohorts.find((i) => `C-${i.cohort_id}` == n.id)) {
          n.image = sphere;
          n.textColor = "white";
        }
        if (n.id === clickedNode["id"]) {
          n.image = sphere;
          n.textColor = "white";
        }
        return n;
      })
      .filter((nf) => {
        if (nf.type !== "child") {
          return nf;
        } else if (allConnectedNodesIds.includes(nf.id)) {
          nf.size = 20;
          return nf;
        }
        return false;
      });
    const allPopulatedNodes = nodes.map((n) => n.id);

    const links = nodesData.links

      .map((li) => {
        let source = li.source["id"] || li.source;
        let target = li.target["id"] || li.target;
        if (allChildNodes.includes(source) || allChildNodes.includes(target)) {
          li.color = "blue";
        }
        return li;
      })
      .filter((nl) => {
        let source = nl.source["id"] || nl.source;
        let target = nl.target["id"] || nl.target;
        if (
          allPopulatedNodes.includes(source) &&
          allPopulatedNodes.includes(target)
        ) {
          return nl;
        }
        return false;
      });

    const updatedData = {
      nodes,
      links,
    };
    return updatedData;
  }
};

const showMainNodes = (allNodesData) => {
  const nodes = allNodesData.nodes.filter((n) => n.type === "Category");
  const nodesIds = nodes.map((n) => n["id"]);
  const links = [];

  for (let index = 0; index < allNodesData.links.length; index++) {
    const element = allNodesData.links[index];

    if (
      nodesIds.includes(element.source["id"] || element.source) &&
      nodesIds.includes(element.target["id"] || element.target)
    ) {
      links.push(element);
    }
  }

  return {
    nodes,
    links,
  };
};
const zoomSelector = (s) => s.transform[2];

const ThreeDChart = ({ data, isConnectable }) => {
  const zoom = useStore(zoomSelector);
  const showContent = zoom === 1;

  const { setCenter } = useReactFlow();
  const {
    setNodes,
    checkedCohorts,
    shownClusterIDs,
    cohortsJSON,
    showMerged,
    shownClusterData,
    cohortType,
  } = useContext(TaiContext);
  const [searchParams] = useSearchParams();
  const isViewedFromBeta = searchParams.get("beta");
  const fgRef = useRef();
  const calibrationData = useSelector((state) => state.calibration.formData);
  const adaccount = useSelector((state) => state.adaccount);
  const { selectedBrand } = adaccount;
  const { selectedAgency } = useSelector((state) => state.common);
  const [graphData, setGraphData] = useState({});
  const [formattedData, setFormattedData] = useState({});
  const [paginatedGraphData, setpaginatedGraphData] = useState({});
  const [view, setView] = useState("interests");
  const [icpGraphData, setIcpGraphData] = useState([]);
  const prefetchedJsonForInterestBased =useSelector((state)=>state.keywordTuningJson)

  const handleChange = (event) => {
    setView(event.target.value);
    handleGraphdata(icpGraphData, event.target.value);
  };

  const Placeholder = () => (
    <div className="flex" style={{ backgroundColor: "#F5F9FF" }}>
      <button
        style={{
          backgroundColor: "#0869FB",
          color: "#FFF",
          border: "none",
          borderRadius: "7px",
          padding: "5px 10px",
          zIndex: 2,
        }}
        onClick={() =>
          setCenter(data.parentPosition.x + 500, data.parentPosition.y + 300, {
            zoom: 1,
            duration: 1000,
          })
        }
      >
        Zoom
      </button>
    </div>
  );

  // deleteKeywordDetails
  const handleFocus = () => {
    if (data.focusedCohort !== undefined && data.focusedCohort !== null) {
      const node = paginatedGraphData?.nodes?.find((ele) => {
        return ele.id === `C-${data.focusedCohort}`;
      });
      if (node) {
        handleClick(node);
      }
      // } else {
      //   setpaginatedGraphData(paginatedGraphData);
    }
  };

  const handleGraphdata = (graphData, newView = view) => {
    if (data.deleteKeywordDetails) {
      graphData = graphData.map((cohort) => {
        if (cohort.cohort_id === data.deleteKeywordDetails.selectedCohort) {
          cohort.interest_relevance = cohort.interest_relevance.filter(
            (keyword, index) =>
              index !== data.deleteKeywordDetails.keywordsToDelete
          );
        }
        return cohort;
      });
    }
    if (calibrationData?.TypeOfCohort === "ICP") {
      const nodesLinksData = cloneDeep(createNodesAndLinks(graphData, newView));
      setFormattedData(nodesLinksData);
      setGraphData(cloneDeep(showMainNodes(nodesLinksData)));
    } else {
      const nodesLinksData = cloneDeep(createNodesAndLinks(graphData, ""));
      setFormattedData(nodesLinksData);
      setGraphData(cloneDeep(showMainNodes(nodesLinksData)));
    }
  };

  useEffect(() => {
    if (data.deleteKeywordDetails) {
      handleGraphdata(data.deleteKeywordDetails.graphData);
      // data.focusedCohort=data.deleteKeywordDetails.selectedCohort
      // handleFocus()
    }
  }, [data.deleteKeywordDetails]);

  // interest_relevance
  useEffect( async () => {
    if (calibrationData?.TypeOfCohort === "ICP") {
      let jsonData = await getJSONDataFromURL(cohortsJSON);
      const sortedGraphData = jsonData?.icps_targetings.map((i, index) => {
        let graphItem = {};
        graphItem.interests = jsonData.graph_data?.interests.find(
          (item) => item?.cohort_id === i?.cohort_id
        );
        graphItem.demographics = jsonData.graph_data?.demographics.find(
          (item) => item?.cohort_id === i?.cohort_id
        );
        graphItem.behaviors = jsonData.graph_data?.behaviors.find(
          (item) => item?.cohort_id === i?.cohort_id
        );
        // graphItem.clus_type = i.clus_type;
        return graphItem;
      });
      setIcpGraphData(sortedGraphData);
      handleGraphdata(sortedGraphData);
    } else {
      const getCohortGraphData =  () => {
            let mergedData = [];
            let unmergedData = [];

            prefetchedJsonForInterestBased.cluster_data.forEach((element) => {
              if (
                element.clus_type === "merged" ||
                element.edited_targetings_flag === true
              ) {
                mergedData.push(element);
              } else {
                unmergedData.push(element);
              }
            });

            const sortedMergedData = mergedData.sort(
              (a, b) => b.relevance - a.relevance
            );
            const sortedUnmergedData = unmergedData.sort(
              (a, b) => b.relevance - a.relevance
            );
            const sortedData = [...sortedMergedData, ...sortedUnmergedData];
            const sortedGraphData = sortedData.map((i) => {
              const itemFromGraphData = prefetchedJsonForInterestBased.graph_data.find(
                (item) => item?.cohort_id === i?.cohort_id
              );
              itemFromGraphData.clus_type = i.clus_type;
              return itemFromGraphData;
            });
            handleGraphdata(sortedGraphData);
      };
      setFormattedData({});
      setGraphData({});
      setpaginatedGraphData({});
      data.setFocusedCohort(
        null,
        "threeDchart",
        setNodes,
        selectedBrand,
        selectedAgency,
        cohortType
      );
      if (cohortsJSON !== "") {
        getCohortGraphData();
      }
    }
  }, [cohortsJSON]);

  const getLinks = (graphDataLinks, selectedNode) => {
    let links=[];
    if (graphDataLinks?.length > 0) {
      links = graphDataLinks.filter((e) => {
        let source;
        let target;
        if (typeof e.source === "string")
          source = Number(e.source.split("-")[1]);
        else source = Number(e.source.id.split("-")[1]);
        if (typeof e.target === "string")
          target =
            e.target.split("-").length > 1
              ? Number(e.target.split("-")[1])
              : e.target;
        else
          target =
            e.target.id.split("-").length > 1
              ? Number(e.target.id.split("-")[1])
              : e.target;
        if (selectedNode) {
          if (typeof target === "string") {
            return (
              shownClusterIDs.includes(`${source}`) &&
              e.source == selectedNode.id
            );
          } else {
            return (
              shownClusterIDs.includes(`${source}`) &&
              shownClusterIDs.includes(`${target}`)
            );
          }
        } else {
          if (typeof target === "string") {
            return shownClusterIDs.includes(`${source}`);
          } else
            return (
              shownClusterIDs.includes(`${source}`) &&
              shownClusterIDs.includes(`${target}`)
            );
        }
      });
    }
    return links;
  };

  useEffect(() => {
    let links;
    if (graphData.links) links = getLinks(graphData.links);
    if (graphData.nodes )
      setpaginatedGraphData({
        links,
        nodes: graphData.nodes.slice(0, shownClusterIDs.length),
      });
  }, [shownClusterIDs, graphData]);

  const drawImageNode = useCallback((node) => {
    const { image, textColor, type } = node;

    if (type === "child") {
      const spriteText = new SpriteText(node.id);
      spriteText.color = "black";
      spriteText.textHeight = 10;

      return spriteText;
    } else {
      let img = sphere;
      if (calibrationData?.TypeOfCohort === "ICP")
        img = node.textColor === "white" ? selectedEllipse : sphere;
      // else
      // img = node.textColor === "white" ? sphere : sphere;
      const imgTexture = new THREE.TextureLoader().load(img);

      const material = new THREE.SpriteMaterial({
        color:
          node.textColor === "white" ? cohortTypes[node.clus_type]?.color : "",
        map: imgTexture,
      });
      const sprite = new THREE.Sprite(material);
      const group = new THREE.Group();

      const spriteText = new SpriteText(node.id);
      spriteText.color = textColor;
      spriteText.fontWeight = 700;
      spriteText.textHeight = 20;
      sprite.scale.set(100, 100, 1);
      group.add(sprite);
      group.add(spriteText);

      return group;
    }
  }, []);

  const nodeThreeObject = useCallback(
    (node) => {
      fgRef?.current?.cameraPosition({ x: -500, y: -500, z: -500 }, 0, 0);

      return drawImageNode(node);
    },
    [drawImageNode]
  );

  useEffect(() => {
    // document.addEventListener('mousemove', setXandY)
    if (fgRef.current) {
      fgRef.current.d3Force("link").distance((link) => {
        return link.distance ? link.distance * 10 : 60;
      });
    }

    // return () => {
    //     document.removeEventListener('mousemove')
    // }
  }, [showContent]);

  useEffect(() => {
    if (data.parentPosition) {
      setCenter(data.parentPosition.x + 500, data.parentPosition.y + 300, {
        zoom: 1,
        duration: 1000,
      });
    }
  }, []);

  useEffect(() => {
    handleFocus();
  }, [data.focusedCohort, checkedCohorts]);

  const handleClick = useCallback(
    (node) => {
      if (node && node.type !== "child") {
        const distance = 250;
        const distRatio = 1 + distance / Math.hypot(node.x, node.y, node.z);
        if(distRatio!==Infinity)
        {fgRef?.current?.cameraPosition(
          {
            x: node.x * distRatio,
            y: node.y * distRatio,
            z: node.z * distRatio,
          }, // new position
          node, // lookAt ({ x, y, z })
          3000 // ms transition duration
        );
        //changeColors of selected node and its children
        }
        const newData = changeSelectedNodeColors(
          cloneDeep(formattedData),
          node,
          checkedCohorts
        );

        data.setFocusedCohort(
          node.id.replace("C-", ""),
          "cohortNode",
          setNodes,
          selectedBrand,
          selectedAgency,
          cohortType
        );

        if (
          graphData.nodes &&
          shownClusterIDs.length > graphData.nodes.length
        ) {
          setpaginatedGraphData(newData);
        } else {
          // const indexNodes = newData.nodes.findIndex(
          //   (e) => Number(e.id.split("-")[1]) === data.graphNodeLength
          // );
          const nodes = [
            ...newData.nodes.filter((item) => {
              if (!item.label) return true;
              else {
                if (shownClusterIDs.includes(item.id.split("-")[1]))
                  return true;
                return false;
              }
            }),
            // .slice(0, indexNodes + 1),
            // ...newData.nodes.filter((e) => e.type === "child"),
          ];
          let links;
          if (calibrationData?.TypeOfCohort == "ICP") {
            links = getLinks(newData.links, node);
          } else {
            links = getLinks(newData.links,node);
          }
          const uniqueNodes = [
            ...new Map(nodes.map((item) => [item.id, item])).values(),
          ];
          setpaginatedGraphData({ links, nodes: uniqueNodes });
        }
      }
    },
    [fgRef, formattedData, graphData, paginatedGraphData, checkedCohorts]
  );

  const handleBackgroundClick = useCallback(() => {
    const mainNodesData = showMainNodes(cloneDeep(formattedData));

    if (
      graphData.nodes &&
      shownClusterIDs.length > mainNodesData.nodes.length
    ) {
      setpaginatedGraphData(mainNodesData);
    } else {
      const links = getLinks(mainNodesData.links);
      setpaginatedGraphData({
        links,
        nodes: mainNodesData.nodes.slice(0, shownClusterIDs.length),
      });
    }
    d3.selectAll("#node-info-container").remove();
    fgRef?.current?.cameraPosition({ x: -500, y: -500, z: -500 }, 0, 0);
  }, [formattedData, graphData, paginatedGraphData]);

  useEffect(() => {
    if (data?.deleteKeywordDetails?.selectedCohort) handleFocus();
  }, [graphData]);

  const isLoading =
    shownClusterData.length === 0 || !(paginatedGraphData?.nodes?.length > 0);

  return (
    <div className="p-2 rounded-md">
      <Handle
        type="target"
        position="left"
        style={{ background: "#0869FB" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <div className="flex border-b-2 align-center p-2">
        <div  className="d-flex align-items-center" style={{width:'100%', justifyContent: calibrationData?.TypeOfCohort === "ICP"?'':'space-between'}}>
          <div
            className="d-flex align-items-center"
            item
            xs={5.5}
            style={{
              fontWeight: "700",
              fontSize: "1.25rem",
              fontFamily: "Lato",
            }}
          >
            Audience Visualiser
          </div>
          <div
            item

            className="d-flex align-items-center justify-content-end"
          >
            {calibrationData?.TypeOfCohort !== "ICP" && (
              <div
                style={{width:'100%',marginRight:'0.5rem'}}
                className="d-flex align-items-center justify-content-end"
              >
              <div style={{display:'flex', gap:'0.75rem', width:'100%', height:'100%'}}>
                {Object.keys(cohortTypes).map((item, index) => (
                  <Fragment key={item}>
                    {(index < 3 || (index === 3 && showMerged)) && (
                      <div
                        item
                        className="d-flex align-items-center"
                        style={{
                            fontWeight: 400,
                            fontFamily: "Inter",
                            fontSize: "0.75rem",
                          
                        }}
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{
                            gap: "0.375rem",
                          }}
                        >
                          <div
                            style={{
                              background: cohortTypes[item].color,
                              borderRadius: "0.5rem",
                              height: "0.625rem",
                              width: "0.625rem",
                              fontSize: "0.75rem",
                              fontWeight: "400",
                              color: "#000",
                            }}
                          ></div>
                          <span>{cohortTypes[item].uiName}</span>
                        </div>
                      </div>
                    )}
                  </Fragment>
                ))}
              </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="py-2 border-xl position-relative"
        style={{
          width: window.innerWidth * 0.38,
          height: window.innerHeight * 0.6,
          overflow: "hidden",
        }}
      >
        <div
          style={
            !showContent
              ? {
                  backgroundColor: "rgba(245, 249, 255, 0.7)",
                  filter: "blur(4px)",
                  zIndex: 1,
                }
              : {}
          }
        >
          <>
            {!isLoading && calibrationData?.TypeOfCohort === "ICP" && (
              <div
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  position: "absolute",
                  bottom: "10px",
                  left: "10px",
                  zIndex: 10,
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    "& *": {
                      fontFamily: "Inter !important",
                      fontSize: "12px !important",
                    },
                  }}
                >
                  <Typography color="#AEB6C4">View &nbsp; </Typography>

                  {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={view}
                    label="View"
                    onChange={handleChange}
                    sx={{
                      color: "#0869FB",
                      border: "1px solid #DDD !important",
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0, border: "none" },
                      "& .MuiOutlinedInput-input ": {
                        px: 0.5,
                        py: 0.2,
                      },
                    }}
                  >
                    <MenuItem
                      sx={{
                        color: "#0869FB",
                        fontFamily: "Inter !important",
                        fontSize: "12px !important",
                      }}
                      value={"interests"}
                    >
                      Interest
                    </MenuItem>
                    <MenuItem
                      sx={{
                        color: "#0869FB",
                        fontFamily: "Inter !important",
                        fontSize: "12px !important",
                      }}
                      value={"behaviors"}
                    >
                      Behaviours
                    </MenuItem>
                    <MenuItem
                      sx={{
                        color: "#0869FB",
                        fontFamily: "Inter !important",
                        fontSize: "12px !important",
                      }}
                      value={"demographics"}
                    >
                      Demographics
                    </MenuItem>
                  </Select>
                </Box>
              </div>
            )}
            {isViewedFromBeta ?
            <div>
              <button
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  position: "absolute",
                  bottom: "10px",
                  right: "10px",
                  zIndex: 10,
                }}
                onClick={() => {
                    window.parent.postMessage(
                      {
                        type: 'SAVE-IN-LOCAL',
                        url: '/cohort-graph',
                        data: JSON.stringify({
                          formattedData: formattedData,
                          graphNodeLength: shownClusterIDs.length,
                          shownClusterIDs,
                        })
                      },
                      '*'
                    );
                }}
              >
                <img src={newtab} alt="add" className="" />
              </button>
            </div>
            :
            <Link to={`/cohort-graph`} target={"_blank"}>
              <button
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  position: "absolute",
                  bottom: "10px",
                  right: "10px",
                  zIndex: 10,
                }}
                onClick={() => {
                  localStorage.setItem(
                    "newTabGraphData",
                    JSON.stringify({
                      formattedData: formattedData,
                      graphNodeLength: shownClusterIDs.length,
                      shownClusterIDs,
                    })
                  );
                }}
              >
                <img src={newtab} alt="add" className="" />
              </button>
            </Link>}
          </>
          {isLoading ? (
            <Box
              className="d-flex align-items-center justify-content-center"
              sx={{
                width: window.innerWidth * 0.38,
                height: window.innerHeight * 0.6,
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <ForceGraph3D
              ref={fgRef}
              backgroundColor="#F5F9FF"
              nodeOpacity={1}
              graphData={paginatedGraphData}
              nodeThreeObject={nodeThreeObject}
              nodeLabel={(node) =>
                `<span style="color: black">${node.label}</span>`
              }
              linkLabel={(link) =>
                `<span style="color: black">relevance : ${
                  Math.round(link.relevance * 100) / 100
                }</span>`
              }
              nodeVal={(node) => node.size}
              linkOpacity={1}
              linkWidth={"width"}
              onNodeClick={handleClick}
              onBackgroundClick={handleBackgroundClick}
              onNodeDragEnd={(node) => {
                node.fx = node.x;
                node.fy = node.y;
                node.fz = node.z;
              }}
              width={window.innerWidth * 0.38}
              height={window.innerHeight * 0.6}
              // width={900}
              // height={975}
              showNavInfo={false}
              zoomToFit={true}
            />
          )}
        </div>

        {!showContent ? (
          <div
            style={{
              height: "100%",
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // backgroundColor: "rgba(245, 249, 255,0.7)",
            }}
          >
            <Placeholder />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ThreeDChart;
